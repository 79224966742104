import React from "react";
import "./Profile.css";
import Typical from "react-typical";
import ScrollService from "../../../Utilities/ScrollService";

// import CV from "../../../Assets/CV.pdf";

export default function Profile() {
  return (
    <div className="profile-container">
      <div className="profile-parent">
        <div className="profile-details">
          <div className="colz">
            <div className="colz-icon">
              <a
                href="https://www.linkedin.com/in/souleimane-zeggai/"
                target="_blank"
                rel="noopener noreferrer"
                className="linkedIn"
              >
                <i className="fa fa-linkedin-square socialsIcons"></i>
              </a>
              <a
                href="https://github.com/souleimane-z"
                target="_blank"
                rel="noopener noreferrer"
                className="github"
              >
                <i className="fa fa-github-square socialsIcons"></i>
              </a>
              {/* <a href="https://www.facebook.com/souzzy" target="_blank" rel="noopener noreferrer" className="facebook">
                <i className="fa fa-facebook-square socialsIcons"></i>
              </a> */}
              <a
                href="https://www.instagram.com/_souzzy_/"
                target="_blank"
                rel="noopener noreferrer"
                className="instagram"
              >
                <i className="fa fa-instagram socialsIcons"></i>
              </a>
              {/* <a
                href="https://twitter.com/souzzy_france"
                target="_blank"
                rel="noopener noreferrer"
                className="twitter"
              >
                <i className="fa fa-twitter socialsIcons"></i>
              </a> */}
            </div>
          </div>
          <div className="profile-details-name">
            <span className="primary-text">
              {" "}
              Bonjour, je m'appelle{" "}
              <span className="highlighted-text">Souleimane</span>
            </span>
          </div>
          <div className="profile-details-role">
            <span className="primary-text">
              {" "}
              <h1>
                {" "}
                <Typical
                  loop={Infinity}
                  steps={[
                    "Développeur WEB",
                    1000,
                    "Front-End",
                    1000,
                    "Référencement (SEO)",
                    1000,
                    "Accessibilité (WCAG)",
                    1000,
                    "React.JS",
                    1000,
                    "Node.JS",
                    1000,
                    "MySQL",
                    1000,
                    "MongoDB",
                    1000,
                    ".",
                    500,
                    "..",
                    500,
                    "...",
                    1000,
                  ]}
                  wrapper="span"
                />
              </h1>
              <span className="profile-role-tagline">
                Développeur WEB qui vous aide à passer au numérique
              </span>
            </span>
          </div>
          <div className="profile-options">
            <button
              className="btn primary-btn"
              onClick={() => ScrollService.scrollHandler.scrollToHireMe()}
            >
              {" "}
              Contact{" "}
            </button>
            <a
              href="https://app.box.com/s/1q0phhz2mvs2j9chunznod539z3h74dw"
              target="_blank"
              rel="noopener noreferrer"
            >
              <button className="btn highlighted-btn"><img src={"https://res.cloudinary.com/dhqh98spd/image/upload/v1700126659/open-file-svgrepo-com_idtf0l.png"} alt="Logo" /> CV</button>
            </a>
          </div>
        </div>
        <div className="profile-picture">
          <div className="profile-picture-background"></div>
        </div>
      </div>
    </div>
  );
}
